import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"


export const GreenInitiativeHeadline = ({ data }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="py-main" ref={trigger}>
            <div className="container mw-xl">
                <div className="mw-md">
                    <h2 className={`${anim(1)}`}>{data?.title}</h2>
                    <p className={`${anim(2)}`}>{data?.text}</p>
                </div>
            </div>
        </section>
    )
}