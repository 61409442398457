import React from "react"
import { useStaticQuery, graphql } from "gatsby"


import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { GreenInitiativeHeadline } from "components/pages/sustainability/green-initiative/headline"
import { GreenInitiativeGallery } from "components/pages/sustainability/green-initiative/gallery"
import { GreenInitiativePoints } from "components/pages/sustainability/green-initiative/points"
import { GreenInitiativeCoverBottom } from "components/pages/sustainability/green-initiative/cover-bottom"

const SustainabilityGreenInitiative = ({ path, pageContext, location }) => {
  const data = useStaticQuery(GET_GREEN_INITIATIVE_PAGE)
  const lang = pageContext?.langKey

  const {
    cover,
    headline,
    gallery,
    projects,
    greenInitiative,
  } = lang === "en"
      ? data?.wordPress.page?.greenInitiativePage
      : data?.wordPress.page?.translation?.greenInitiativePage
      || data?.wordPress.page?.greenInitiativePage


  const coverBottomSlug = data?.wordPress.page?.greenInitiativePage?.projects.featured[0]

  const { seo, backgroundColor } = lang === "en"
    ? data?.wordPress?.page
    : data?.wordPress?.page?.translation
    || data?.wordPress?.page

  const bgColor = backgroundColor?.backgroundColor?.color
  const SEODefaultImg = cover?.image?.sourceUrl

  return (
    <Layout path={path} theme={bgColor} lang={lang}>
      <SEO
        title={seo?.title}
        desc={seo?.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo?.opengraphTitle}
        fbDesc={seo?.opengraphDescription}
        url={location?.href}
        twitterTitle={seo?.twitterTitle}
        twitterDesc={seo?.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover data={cover} theme={bgColor} breadcrumb={"/sustainability/green-initiative"} />
      <GreenInitiativeHeadline data={headline} />
      <GreenInitiativeGallery data={gallery} />
      <GreenInitiativePoints data={greenInitiative} />
      <GreenInitiativeCoverBottom data={projects?.featured[0]} slug={coverBottomSlug} lang={lang} />
    </Layout>
  )
}

export default SustainabilityGreenInitiative


const GET_GREEN_INITIATIVE_PAGE = graphql`
query GREEN_INITIATIVE {
  wordPress {
    page(id: "green-initiative", idType: URI) {
      backgroundColor {
        backgroundColor {
          color
        }
      }
      seo {
        title
        metaDesc
        twitterTitle
        twitterDescription
        opengraphTitle
        opengraphDescription
      }
      greenInitiativePage {
        cover {
          title
          image {
            sourceUrl
            altText
          }
        }
        headline {
          title
          text
        }
        gallery {
          images {
            sourceUrl
            altText
          }
        }
        projects {
          featured {
            ... on WordPress_Project {
              slug
              projectDetailsPage {
                cover {
                  title
                  image {
                    sourceUrl
                    altText
                  }
                }
                headline {
                  text
                }
              }
              projectCategories {
                nodes {
                  slug
                }
              }
            }
          }
        }
        greenInitiative {
          title
          list {
            title
            text
            icon {
              sourceUrl
              altText
            }
          }
        }
      }
      translation(language: ID) {
        backgroundColor {
          backgroundColor {
            color
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          opengraphTitle
          opengraphDescription
        }
        greenInitiativePage {
          cover {
            title
            image {
              sourceUrl
              altText
            }
          }
          headline {
            title
            text
          }
          gallery {
            images {
              sourceUrl
              altText
            }
          }
          projects {
            featured {
              ... on WordPress_Project {
                slug
                projectDetailsPage {
                  cover {
                    title
                    image {
                      sourceUrl
                      altText
                    }
                  }
                  headline {
                    text
                  }
                }
                projectCategories {
                  nodes {
                    slug
                  }
                }
              }
            }
          }
          greenInitiative {
            title
            list {
              title
              text
              icon {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
  }
}
`