import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"


export function GreenInitiativePoints({ data }) {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main bg-light" ref={trigger}>
      <div className="container">
        <h2 className={`mb-4 mb-md-0${anim(1)}`}>{data?.title}</h2>
        <div className="row mt-md-5">
          {data?.list?.map((item, i) => {
            return (
              <div key={i} className={`col-md-6 d-flex py-2 ${anim(2 + i)}`}>
                <img src={item?.icon?.sourceUrl} className="h-50px" alt="icon" />
                <div className="mw-md-400px ml-4">
                  <h5 className="mb-0">{item?.title}</h5>
                  <p className="font-weight-light text-gray-400">
                    {item?.text}
                  </p>
                </div>
              </div>
            )
          })}
        </div>

      </div>
    </section>
  )
}
