import React from 'react'

import { SliderDuti } from "components/anti/slider/slider-duti"

export function GreenInitiativeGallery({ data }) {
    return (
        <section className="pb-md-main">
            <div className="container mw-xl px-sm-down-0">
                <SliderDuti
                    className="gallery-sustainabiliity pt-4"
                    values={data?.images}
                />
            </div>
        </section>
    )
}
