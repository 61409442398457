import React, { useContext } from 'react'


import { Image } from "components/anti/image/image"
import { Button } from "components/anti/buttons/buttons"


export function GreenInitiativeCoverBottom({ data, slug, lang }) {

    const cover = data?.projectDetailsPage?.cover
    const categories = slug?.projectCategories?.nodes[0]?.slug
    return (
        <section className="py-main sc-greeninitiative-coverBottom overflow-hidden">
            <Image
                src={cover?.image?.sourceUrl}
                alt={cover?.image?.sourceUrl ? cover?.image?.sourceUrl : "cover"}
                ratio="r-1-1 r-md-16-9"
                className="img-fluid coverBottom-cover"
            />
            <div className="container text-white">
                <div className="w-md-600px">
                    <h2>{cover?.title}</h2>
                    <p className="py-3 d-md-block d-none">{data?.projectDetailsPage?.headline?.text}</p>
                    <p className="d-md-none text-truncate-threeline">{data?.projectDetailsPage?.headline?.text}</p>
                </div>
                <Button variant="link" className="text-white" link={`${lang === "en" ? "" : "/id"}/development/${categories}/${slug.slug}`} >
                    {lang === "en" ? "Read More" : "Baca Selengkapnya"}
                </Button>
            </div>
            {/* <Card
                variant="overlay"
                imgOverlay="40"
                title={data.title}
                text={data.text}
                textClassName="w-md-500px"
                img={data.image.sourceUrl}
                imgHeight="h-200px h-md-300px"
                cardBodyClassName="p-md-6 p-3 d-flex flex-column justify-content-center"
            >
                {data.buttons?.map((item, i) => {
                    return (
                        <Button key={i} className="px-0 text-white">
                            {item.text}
                            <img src={ArrowNxWht} className="icon ml-1" alt="icon" />
                        </Button>
                    )
                })}
            </Card> */}
        </section>
    )
}
